import request from '@/utils/request'

// 订单支付
export const orderPay = data => {
  return request({
    url: '/goods_order/pay',
    method: 'post',
    data
  })
}
